import React from 'react';
import { PathService } from '../../services';
import { CareerOpeningsViewContext, TemplateProps } from '../../types';
import { CareerOpeningsView } from '../../views';

const CareerOpenings: React.FunctionComponent<
  TemplateProps<CareerOpeningsViewContext>
> = ({ location }) => {
  PathService.set(location.pathname);
  return (
    <CareerOpeningsView
    // jobCountries={pageContext.jobCountries}
    // jobTypes={pageContext.jobTypes}
    // jobs={JSON.parse(pageContext.jobs)}
    />
  );
};

export default CareerOpenings;
